.tag-selector .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e87722;
  border-color: #e87722;
}

.tag-selector .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #e87722;
}

.tag-selector .ant-checkbox-checked::after {
  border-color: #e87722;
}

.tag-selector .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #e87722;
}
