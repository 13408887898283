.loading {
  height: 10em;
  width: 10em;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading-div {
  text-align: center;
  padding: 15px;
}

/* Transparent Overlay */
/*
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.7)
}
*/
.loading-dialog {
  position: fixed;
  z-index: 9999;
  height: 10em;
  width: 10em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading-dialog:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.7)
}
