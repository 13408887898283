.header {
  color: white;
  background: rgb(242, 99, 35);
  background: linear-gradient(180deg, rgba(242, 99, 35, 1) 0%, rgba(232, 119, 34, 1) 100%);
  padding: 0;
}

.sider {
  height: 100vh;
  background-color: white;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 15;
}

.ant-menu-submenu:hover,
.ant-menu-submenu-selected,
.ant-menu-submenu-selected:hover,
.ant-menu-submenu-title:hover,
.ant-menu-vertical .ant-menu-submenu-selected {
  color: #e87722;
}

.ant-menu-submenu-arrow {
  color: #e87722;
}

.ant-menu-item-selected,
.ant-menu-item-selected > a {
  color: #e87722;
}

.ant-menu-item > a:hover {
  color: #e87722;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #d8d8d8;
}

.trigger {
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 25px;
}

.trigger:hover {
  color: #1890ff;
}
