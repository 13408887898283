.ant-radio-inner::after {
  background-color: #E87722;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #E87722;
}

.ant-radio-checked::after {
  border-color: #E87722;
}

.ant-radio-input:focus + .ant-radio-inner {
  border-color: #E87722;
}