*,
body {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

:root {
  --primary-color: #e77822;
  --hover-color: #ffb732;
  --focus-color: #d95e00;
  --menu-selected-color: #ffede0;
}

/* Menu Styles */
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid var(--primary-color);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--menu-selected-color);
}

/** Input Number */
.ant-input-number:hover {
  border-color: var(--primary-color);
}

.ant-input-number-focused {
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 150, 87, 0.2);
}

.ant-input:active {
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 150, 87, 0.2);
}
.ant-input:hover,
.ant-input:focus {
  border-color: var(--primary-color);
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 150, 87, 0.2);
}

/* Primary Button */
.ant-btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.ant-btn-primary:active {
  background-color: transparent;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.ant-btn-primary:hover {
  background-color: var(--hover-color);
  border-color: var(--hover-color);
  color: white;
}

.ant-btn-primary:focus {
  background-color: var(--focus-color);
  border-color: var(--focus-color);
  color: white;
}

/** Affix **/
.ant-input:not(.ant-input-disabled):hover {
  border-color: var(--hover-color);
}

.ant-input-affix-wrapper:hover .ant-input {
  border-color: var(--hover-color) !important;
  outline: 0;
}
